/**
 * diy自定义组件
 * */
export default {
  namespaced: true,
  state: {
    configName: '',
    pageTitle: '',
    pageName: '' || '模板',
    pageTop: true,
    pageShow: 1,
    pageColor: 0,
    pagePic: 0,
    pageColorPicker: '#f5f5f5',
    pageTabVal: 0,
    pagePicUrl: '',
    returnAddress: '',
    titleColor: '#000000',
    titleBgColor: '#fff',
    // 已知组件列表默认数据 数组
    defaultArray: {}
  },
  mutations: {
    FOOTER: function FOOTER(state, data) {
      state.pageFooter.status.title = data.title;
      state.pageFooter.menuList[2] = data.name;
    },
    /**
     * 隐藏组件，更新数据显示值
     * @constructor
     */
    UPDATESHOW: function UPDATESHOW(state, data) {
      state.defaultArray[data.num].isShow = !state.defaultArray[data.num].isShow;
    },
    /**
     * @description 默认配置push到数组里面
     * @param {Object} state vuex state
     * @param {Object} data
     * 把默认数据添加到默认数组里面，解耦重复组件公用一条配置的问题
     */
    ADDARRAY: function ADDARRAY(state, data) {
      data.val.id = 'id' + data.val.timestamp;
      state.defaultArray[data.num] = data.val;
    },
    /**
     * @description 删除列表第几个默认数据
     * @param {Object} state vuex state
     * @param {Object} data 数据
     */
    DELETEARRAY: function DELETEARRAY(state, data) {
      var tempObj = delete state.defaultArray[data.num];
    },
    /**
     * @description 删除列表第几个默认数据
     * @param {Object} state vuex state
     * @param {Object} data 数据
     */
    ARRAYREAST: function ARRAYREAST(state, data) {
      var tempObj = delete state.defaultArray[data];
    },
    /**
     * @description 数组排序
     * @param {Object} state vuex state
     * @param {Object} data 位置index记录
     */
    defaultArraySort: function defaultArraySort(state, data) {
      var newArr = objToArr(state.defaultArray);
      var sortArr = [];
      var newObj = {};
      function objToArr(data) {
        var obj = Object.keys(data);
        var m = obj.map(function (key) {
          return data[key];
        });
        return m;
      }
      function swapArray(arr, index1, index2) {
        var oldObj = {};
        var newObj = {};
        var active = 0;
        arr.forEach(function (el, index) {
          if (!el.id) {
            el.id = 'id' + el.timestamp;
          }
          data.list.forEach(function (item, j) {
            if (el.id == item.id) {
              el.timestamp = item.num;
            }
          });
        });
        return arr;
      }
      if (data.oldIndex != undefined) {
        sortArr = JSON.parse(JSON.stringify(swapArray(newArr, data.newIndex, data.oldIndex)));
      } else {
        newArr.splice(data.newIndex, 0, data.element.data().defaultConfig);
        sortArr = JSON.parse(JSON.stringify(swapArray(newArr, 0, 0)));
      }
      for (var i = 0; i < sortArr.length; i++) {
        newObj[sortArr[i].timestamp] = sortArr[i];
      }
      state.defaultArray = Object.assign({}, newObj);
    },
    /**
     * @description 更新数组某一组数据
     * @param {Object} state vuex state
     * @param {Object} data
     */
    UPDATEARR: function UPDATEARR(state, data) {
      for (var k in state.defaultArray) {
        if (state.defaultArray[k].id == data.val.id) {
          state.defaultArray[k] = data.val;
        }
      }
      var value = Object.assign({}, state.defaultArray);
      state.defaultArray = value;
    },
    /**
     * @description 保存组件名称
     * @param {Object} state vuex state
     * @param {string} data
     */
    SETCONFIGNAME: function SETCONFIGNAME(state, name) {
      state.configName = name;
    },
    /**
     * @description 默认组件清空
     * @param {Object} state vuex state
     * @param {string} data
     */
    SETEMPTY: function SETEMPTY(state, name) {
      state.defaultArray = {};
    },
    UPTITLE: function UPTITLE(state, val) {
      state.pageTitle = val;
    },
    UPNAME: function UPNAME(state, val) {
      state.pageName = val;
    },
    UPTOP: function UPTOP(state, val) {
      state.pageTop = val;
    },
    UPSHOW: function UPSHOW(state, val) {
      state.pageShow = val;
    },
    UPCOLOR: function UPCOLOR(state, val) {
      state.pageColor = val;
    },
    UPPIC: function UPPIC(state, val) {
      state.pagePic = val;
    },
    UPPICKER: function UPPICKER(state, val) {
      state.pageColorPicker = val;
    },
    UPRADIO: function UPRADIO(state, val) {
      state.pageTabVal = val;
    },
    UPPICURL: function UPPICURL(state, val) {
      state.pagePicUrl = val;
    },
    UPPReturnAddress: function UPPReturnAddress(state, val) {
      state.returnAddress = val;
    },
    UPPTitleColor: function UPPTitleColor(state, val) {
      state.titleColor = val;
    },
    UPPTitleBgColor: function UPPTitleBgColor(state, val) {
      state.titleBgColor = val;
    },
    /**
     * @description 更新foot菜单配置
     * @param {Object} state vuex state
     * @param {string} data
     */
    footUpdata: function footUpdata(state, data) {
      state.pageFooter = [];
      state.pageFooter = data;
    },
    /**
     * @description 更新foot自定义开关
     * @param {Object} state vuex state
     * @param {string} data
     */
    footStatus: function footStatus(state, data) {
      state.pageFooter.status.status = data;
    },
    /**
     * @description 更新foot配置
     * @param {Object} state vuex state
     * @param {string} data
     */
    footPageUpdata: function footPageUpdata(state, data) {
      state.pageFooter = data;
    },
    /**
     * @description 更新title配置
     * @param {Object} state vuex state
     * @param {string} data
     */
    titleUpdata: function titleUpdata(state, data) {
      state.pageTitle = data;
    },
    /**
     * @description 更新name配置
     * @param {Object} state vuex state
     * @param {string} data
     */
    nameUpdata: function nameUpdata(state, data) {
      state.pageName = data;
    },
    /**
     * @description 更新top标题显示配置
     * @param {Object} state vuex state
     * @param {string} data
     */
    topUpdata: function topUpdata(state, data) {
      state.pageTop = data;
    },
    //
    showUpdata: function showUpdata(state, data) {
      state.pageShow = data;
    },
    colorUpdata: function colorUpdata(state, data) {
      state.pageColor = data;
    },
    picUpdata: function picUpdata(state, data) {
      state.pagePic = data;
    },
    /**
     * @description 更新页面背景色
     * @param {Object} state vuex state
     * @param {string} data
     */
    pickerUpdata: function pickerUpdata(state, data) {
      state.pageColorPicker = data;
    },
    radioUpdata: function radioUpdata(state, data) {
      state.pageTabVal = data;
    },
    picurlUpdata: function picurlUpdata(state, data) {
      state.pagePicUrl = data;
    },
    /**
     * 更新页面设置中返回地址
     * @param state
     * @param data
     */
    returnAddressUpdata: function returnAddressUpdata(state, data) {
      state.returnAddress = data;
    },
    /**
     * 更新页面设置中顶部颜色
     * @param state
     * @param data
     */
    titleBgColorUpdata: function titleBgColorUpdata(state, data) {
      state.titleBgColor = data;
    },
    /**
     * 更新页面设置中顶部颜色
     * @param state
     * @param data
     */
    titleColorUpdata: function titleColorUpdata(state, data) {
      state.titleColor = data;
    }
  },
  actions: {
    getData: function getData(_ref, data) {
      var commit = _ref.commit;
    }
  }
};